<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        name: 'referral',
        actions: [
          {
            text: 'Resend email',
            message: 'resend email to',
          },
        ],
        headers: [
          { text: 'Recipient email', value: 'email', filter: true, width: '400px' },
          { text: 'Name', value: 'name', filter: true },
          {
            text: 'Credit',
            value: 'credit',
            component: 'dtPrice',
          },
          { text: 'Status', value: 'status', capitalize: true },
          { text: 'Sender email ', value: 'createdBy.email', width: '400px' },
        ],
        header: {
          created: 'Sent',
        },
        hideHeader: {
          updated: true,
        },
        hideAction: true,
      },
    };
  },
};
</script>
